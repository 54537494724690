<script setup lang="ts">
import src from '@/assets/icons/common/copy.svg';
import type { IconProps } from '@/interfaces';

const props = defineProps<IconProps>();
</script>

<template>
  <common-icon class="icon-common-copy" v-bind="props" :src="src"></common-icon>
</template>

<style scoped lang="scss"></style>
