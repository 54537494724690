<script setup lang="ts">
  import spaceFilter from '@/filters/space.filter';
  import zeroFilter from '@/filters/zero.filter';
  import { Wallet } from '@/interfaces';

  const { t } = useI18n();

  const emit = defineEmits<{
    (e: 'timeout'): void;
  }>();

  defineProps<{
    error: string;
  }>();

  const walletStore = useWalletStore();
  const wallet = computed(() => walletStore.getWallet as Wallet);

  const state = reactive({
    timer: 0,
    seconds: 1200,
  });

  const startTimer = () => {
    state.timer = window.setInterval(() => {
      state.seconds--;
      if (state.seconds === 0) {
        stopTimer();
        emit('timeout');
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(state.timer);
  };

  const currentTimer = computed(() => {
    const minutes = Math.floor(state.seconds / 60);
    const seconds = Math.floor(state.seconds % 60);
    return `${zeroFilter(minutes, 2)}:${zeroFilter(seconds, 2)}`;
  });

  onMounted(() => {
    startTimer();
  });

  onUnmounted(() => {
    stopTimer;
  });
</script>

<template>
  <section class="wallet-info">
    <div class="wallet-info--block">
      <div class="amount block">
        <div class="block-title">{{ t('components.common.wallet.amount')}}</div>
        <div class="block-container">
          <span class="value">{{ spaceFilter(wallet.amount) }}</span>
          <span class="ml-2">USDT</span>
        </div>
      </div>
      <div class="timer block">
        <div class="block-title">{{ t('components.common.wallet.time')}}</div>
        <div class="block-container">
          <span class="value">{{ currentTimer }}</span>
        </div>
      </div>
    </div>
    <transition name="scale">
      <div v-if="error" class="wallet-info--error">{{ error }}</div>
    </transition>
  </section>
</template>

<style scoped lang="scss">
.wallet-info {
  @apply flex flex-col gap-4;
  &--error {
    @apply text-accent font-medium text-center text-sm;
  }
  &--block {
    @apply grid grid-cols-2 gap-4;
    .block {
      @apply w-full py-2 px-3 rounded-md bg-gray-4 flex flex-col gap-1;
      &-title {
        @apply text-gray-1 text-sm;
      }
      &-container {
        .value {
          @apply text-2xl font-medium;
        }
      }
    }
  }
}
</style>
