<script setup lang="ts">
  import QRCodeVue3 from 'qrcode-vue3';

  const { t } = useI18n();

  const walletStore = useWalletStore();
  const warningList = [
    t('components.common.wallet.warning.1'),
    t('components.common.wallet.warning.2'),
    t('components.common.wallet.warning.3'),
  ];
</script>

<template>
  <section class="wallet-container">
    <div class="wallet-container--description">
      {{ t('components.common.wallet.description') }}
    </div>
    <div class="wallet-container--inner">
      <div class="wallet-container--qrcode">
        <QRCodeVue3
          imgclass="code-qr"
          :width="220"
          :height="220"
          :value="walletStore.getWallet?.address"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'M' }"
          :dotsOptions="{ type: 'square', color: '#303030' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#303030' }"
          :cornersDotOptions="{ type: 'dot', color: '#303030' }"
          :backgroundOptions="{ color: '#fff' }"
        />
      </div>
    </div>
    <div class="wallet-container--warning">
      <div class="item" v-for="(item, index) of warningList" :key="index">
        <common-icon-common-alert-fill size="20px"></common-icon-common-alert-fill>
        <span>{{ item }}</span>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.wallet-container {
  @apply border border-solid border-gray-4 rounded-xl py-4 flex flex-col gap-4;
  &--description {
    @apply px-4;
  }
  &--inner {
    @apply flex items-center justify-center;
  }
  &--qrcode {
    @apply border border-solid border-gray-4 rounded-lg p-4;
  }
  &--warning {
    border-top: 1px solid;
    @apply border-gray-4 pt-4 px-6 flex flex-col gap-2;
    .item {
      @apply flex items-center gap-2 text-gray-2 text-sm;
    }
  }
}
</style>
