<script setup lang="ts">
  import { copyText } from '@/utils';

  const { t } = useI18n();
  const walletStore = useWalletStore();
  const notificationStore = useNotificationStore();

  const copyAddress = () => {
    copyText(walletStore.getWallet?.address || '');

    notificationStore.create({ message: t('components.common.wallet.copyAddress'), type: 'info' });
  };
</script>

<template>
  <section class="wallet-address">
    <div class="wallet-address--title">{{ t('components.common.wallet.address') }}</div>
    <div class="wallet-address--value">{{ walletStore.getWallet?.address }}</div>
    <div class="wallet-address--copy" @click="copyAddress">
      <common-icon-common-copy class-mods="white" size="24px"></common-icon-common-copy>
    </div>
  </section>
</template>

<style scoped lang="scss">
.wallet-address {
  @apply w-full py-2 px-4 rounded-md bg-gray-5 relative;
  &--title {
    @apply text-gray-1 text-sm font-medium mb-1;
  }
  &--copy {
    @apply w-9 h-9 bg-accent rounded-md flex items-center justify-center absolute top-3.5 right-4 cursor-pointer;
  }
}
</style>
