import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { requestSetAuthHeader, responseError } from '@/utils/axios/interceptors';

class ApiWallet extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/wallet'),
          withCredentials: true,
        },
      },
      {
        request: {
          success: [requestSetAuthHeader],
        },
        response: {
          error: [responseError],
        },
      },
    );
  }

  checkPayment() {
    return this.post('/check');
  }
}

export const apiWallet = new ApiWallet();
