<script setup lang="ts">
  import { useWalletStore } from '@/stores';
  import { apiWallet } from '@/services/api/wallet';

  import iconCancel from '@/assets/icons/arrow/cancel.svg';
  import { AxiosError } from 'axios';

  const { t } = useI18n();
  const walletStore = useWalletStore();
  const notificationStore = useNotificationStore();

  const destroyModal = () => {
    if (confirm(t('components.common.wallet.confirmDestroy'))) destroy();
  };

  const destroy = () => {
    submitAction();
    walletStore.destroy();
  };

  const state = reactive({
    pending: false,
    error: '',
  });

  const timeoutAction = () => {
    notificationStore.create({
      message: t('components.common.wallet.timeIsUp'),
      type: 'error',
    });
    destroy();
  };

  const submitAction = async () => {
    if (state.pending) return;
    state.pending = true;

    apiWallet.checkPayment()
      .then(() => {
        notificationStore.create({
          message: t('components.common.wallet.success'),
          type: 'success',
        });
        walletStore.destroy();
      })
      .catch(err => {
        console.error(err)
        state.error = err.data.message;
      })
      .finally(() => {
        state.pending = false;
      })
  };

  onMounted(() => {
    window.onbeforeunload = () => {
      submitAction();
      return t('components.common.wallet.confirmExit');
    };
  });

  onUnmounted(() => {
    window.onbeforeunload = null;
  });
</script>

<template>
  <section class="overlay flex-c" @click="destroyModal">
    <div class="wallet flex-col" @click.stop>
      <div class="wallet-cancel">
        <common-icon :src="iconCancel" @click="destroyModal"></common-icon>
      </div>
      <div class="wallet-title">{{ t('components.common.wallet.topUp') }} USDT</div>
      <common-wallet-info :error="state.error" @timeout="timeoutAction"></common-wallet-info>
      <common-wallet-container></common-wallet-container>
      <common-wallet-address></common-wallet-address>
      <div class="wallet-action">
        <common-button
          :text="t('components.common.wallet.paid')"
          class-mods="btn-accent btn-small btn-fit"
          @click="submitAction"
        ></common-button>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.overlay {
  background: rgba(0, 0, 0, 0.6);
  @apply fixed flex items-start justify-center top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden z-20 gap-8;
  .wallet {
    @apply my-10 relative rounded-xl bg-white p-6 gap-8 z-20 w-[524px];
    &-title {
      @apply text-2xl font-semibold;
    }
    &-cancel {
      @apply absolute top-4 right-4;
    }
    &-action {
      @apply flex justify-end;
    }
  }
}
</style>
