export * from './file';
export * from './math';
export * from './object';
export * from './verify';

export const copyText = (copy: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(copy);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = copy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(copy) : rej();
      textArea.remove();
    });
  }
};
